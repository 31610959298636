import { FunctionComponent } from "react";

const Address: FunctionComponent = () => {
  return (
    <div className="rounded-xl bg-white p-6 shadow-md border border-gray-300">
      <h2 className="text-xl font-bold mb-4">
        Endereço
      </h2>
      rua
    </div>
  );
}
export default Address;
