import { FunctionComponent } from "react";

const Payment: FunctionComponent = () => {
  return (
    <div className="rounded-xl bg-white p-6 shadow-md border border-gray-300">
      <h2 className="text-xl font-bold mb-4">
        Pagamento
      </h2>
      Numero do cartão
    </div>
  );
}
export default Payment;
