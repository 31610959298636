import React, { useEffect, useState } from 'react';
import IdentificationSection from './components/IdentificationSection';
import PaymentSection from './components/PaymentSection';
import PurchaseSection from './components/PurchaseSection';
import Footer from './components/Footer';
import OffersSection from './components/OffersSection';
import PageProduct from './components/PageProduct';

interface Config {
  colorTheme: string;
  title: string;
  description: string;
  pixelId?: string; // pixelId pode ser opcional
  [key: string]: any; // Permite outras chaves dinâmicas para garantir flexibilidade
}

interface PaymentMethod {
  id: string;
  name: string;
  imageUrl: string;
}


function App() {
  const [config, setConfig] = useState<Config | null>(null);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  useEffect(() => {
    // Buscar configurações a partir da API do backend
    fetch('/config')
      .then((response) => response.json())
      .then((data: Config) => {
        console.log('Config carregada:', data);

        // Atualiza as configurações
        setConfig(data);

        // Verifica se o pixelId está presente
        if (data.pixelId) {
          console.log('Pixel ID encontrado:', data.pixelId);

          // Carregar o script do Facebook Pixel de forma assíncrona
          const script = document.createElement('script');
          script.src = 'https://connect.facebook.net/en_US/fbevents.js';
          script.async = true;

          // Quando o script for carregado, inicialize o Pixel
          script.onload = () => {
            console.log('Script do Facebook Pixel carregado');

            // A função para inicializar o fbq no objeto window
            if (!window.fbq) {
              (function(f: any, b: any, e: any, v: any, n: any, t: any, s: any) {
                if (f.fbq) return;
                n = f.fbq = function() {
                  n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = true;
                n.version = '2.0';
                n.queue = [];
                t = b.createElement(e);
                t.async = true;
                t.src = v;

                s = b.getElementsByTagName(e)[0]; // Obtém o primeiro script da página
                if (s && s.parentNode) {  // Verifica se 's' e 's.parentNode' não são undefined
                  s.parentNode.insertBefore(t, s);
                } else {
                  b.body.appendChild(t); // Se não tiver um script, coloca no final do body
                }
              })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js', null, null, null);
            }

            // Inicializa o Pixel com o pixelId
            (window as any).fbq('init', data.pixelId);
            console.log('Pixel do Facebook inicializado');

            // Envia o evento PageView
            (window as any).fbq('track', 'PageView');
            console.log('Evento PageView enviado');
          };

          // Adiciona o script ao head do documento
          document.head.appendChild(script);
        } else {
          console.log('Nenhum Pixel ID encontrado');
        }

        // Determina os métodos de pagamento com base no domínio
        const domain = window.location.hostname;
        console.log('Domínio detectado:', domain);
        const methods = data[domain]?.paymentMethods || data.default?.paymentMethods || [];
        setPaymentMethods(methods);
      })
      .catch((error) => console.error('Erro ao carregar configurações:', error));
  }, []);

  return (

    <div style={{ backgroundColor: config ? config.colorTheme : 'defaultColor' }}>
      <div className="mx-auto max-w-6xl p-4 ">
        <div className="grid xl:grid-cols-3 gap-4 items-start">
          <div className="xl:col-span-2 grid gap-4">
            <div className="grid gap-10 rounded-md bg-white p-8 ">
              <PageProduct />
            </div>
            <div className="grid gap-10 rounded-md bg-white p-8 "  >
              <IdentificationSection config={config} />
              <PaymentSection paymentMethods={paymentMethods} />
              <OffersSection />
              <hr className="-mx-8 bg-gray-200 h-px"/>
              <Footer />
            </div>
          </div>
          <div className="grid gap-10 rounded-md bg-white p-8 xl:order-last sticky top-4 ">
            <PurchaseSection />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
