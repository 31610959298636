import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="grid gap-5">
      <p className="text-sm">
        Ao clicar em "Compre agora", você concorda com os{' '}
        <button className="link text-action underline font-medium underline-offset-4" onClick={() => alert('Termos de Compra')}>Termos de Compra</button> e está ciente da{' '}
        <button className="link text-action underline font-medium underline-offset-4" onClick={() => alert('Política de Privacidade')}>Política de Privacidade</button>.
      </p>
    </footer>
  );
};

export default Footer;
