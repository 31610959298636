import React, { useState } from 'react';
import {ReactComponent as CreditCardIcon} from "../icons/credit-card.svg";
import {ReactComponent as PixIcon} from "../icons/pix.svg";


interface PaymentMethod {
  id: string;
  name: string;
  imageUrl: string;
}

interface Props {
  paymentMethods: PaymentMethod[];
}

const PaymentSection: React.FC<Props> = ({ paymentMethods }) => {
  const [method, setMethod] = useState<'credit-card' | 'pix'>('credit-card')
  return (
    <section data-layername="border" className="flex flex-col">
      <div className="flex flex-wrap gap-5 justify-between w-full max-md:mr-0.5 max-md:max-w-full">
        <h3 data-layername="heading3→Pagamento" className="my-auto text-lg font-bold leading-snug">Pagamento</h3>
      </div>
      <div className="flex flex-wrap gap-2 items-start self-start mt-7">
        <button className={`size-14 border rounded-md grid place-items-center hover:shadow-md transition-all duration-300 ${method === "credit-card" ? 'shadow-md' :''}`} onClick={() => setMethod('credit-card')}>
          <span className="sr-only">Cartão de Crédito</span>
          <CreditCardIcon className="h-12 stroke-action"/>
        </button>
        <button className={`size-14 border rounded-md grid place-items-center hover:shadow-md transition-all duration-300 ${method === "pix" ? 'shadow-md' :''}`} onClick={() => setMethod('pix')}>
          <span className="sr-only">Pix</span>
          <PixIcon className="h-12 text-action"/>
        </button>
      </div>
      {method === 'pix' && (
        <div className="flex flex-col">
          <div className="flex flex-col">
            <label htmlFor="cardHolder" data-layername="label→NomeDoTitular" className="self-start mt-6 text-base font-medium leading-none text-black">
              Nome completo
            </label>
            <input
              id="cardHolder"
              type="text"
              data-layername="border"
              className="p-4 mt-1.5 text-base rounded border border-solid border-zinc-600 text-neutral-400 max-md:pr-5 max-md:max-w-full"
              placeholder="Digite o nome completo"
            />
             <label htmlFor="cardHolder" data-layername="label→NomeDoTitular" className="self-start mt-6 text-base font-medium leading-none text-black">
             CPF/CNPJ
            </label>
            <input
              id="cardHolder"
              type="text"
              data-layername="border"
              className="p-4 mt-1.5 text-base rounded border border-solid border-zinc-600 text-neutral-400 max-md:pr-5 max-md:max-w-full"
              placeholder="Digite seu CPF/CNPJ"
            />
          </div>
        </div>
      )}
      {method === 'credit-card' && (
        <div className="flex flex-col">
          <label htmlFor="cardHolder" data-layername="label→NomeDoTitular" className="self-start mt-6 text-base font-medium leading-none text-black">
            Nome do titular
          </label>
          <input
            id="cardHolder"
            type="text"
            data-layername="border"
            className="p-4 mt-1.5 text-base rounded border border-solid border-zinc-600 text-neutral-400 max-md:pr-5 max-md:max-w-full"
            placeholder="Digite o nome do titular"
          />
          <label htmlFor="cardNumber" data-layername="label→NumeroDoCartao" className="self-start mt-6 text-base font-medium leading-none text-black">
            Número do cartão
          </label>
          <div data-layername="border" className="flex flex-wrap gap-5 justify-between py-2 pr-2 pl-4 mt-1.5 text-base rounded border border-solid border-zinc-600 text-neutral-400 max-md:max-w-full">
            <input
              id="cardNumber"
              type="text"
              data-layername="digiteONumeroDoSeuCartao"
              className="my-auto bg-transparent border-none flex-grow"
              placeholder="Digite o número do seu cartão"
            />
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d8d54ee8cf1c1f62633e78221ec4b7881656a39d5155050468c5dc3bda3c1bca?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="Card type" className="object-contain shrink-0 rounded aspect-[1.44] w-[46px]" />
          </div>
          <div className="grid gap-5 lg:grid-cols-3">
            <div className="flex flex-col flex-1 grow shrink-0 basis-0 w-fit mt-6">
              <label htmlFor="expiry" data-layername="label→Vencimento" className="mb-3 self-start font-medium leading-none text-black">Vencimento</label>
              <input
                id="expiry"
                type="text"
                data-layername="border"
                className="px-4 h-14 rounded border border-solid border-zinc-600 text-neutral-400 max-md:pr-5 "
                placeholder="MM/AA"
              />
            </div>
            <div className="flex flex-col flex-1 grow shrink-0 basis-0 w-fit mt-6">
              <label htmlFor="cvv" data-layername="label→Cvv" className="mb-3 self-start font-medium leading-none text-black" >CVV</label>
              <input
                id="expiry"
                type="text"
                data-layername="border"
                className="px-4 h-14 rounded border border-solid border-zinc-600 text-neutral-400 max-md:pr-5 "
                placeholder="MM/AA"
              />
            </div>
            <div className="flex flex-col flex-1 grow shrink-0 basis-0 w-fit mt-6">
              <label htmlFor="installment" data-layername="label→Parcelamento" className="mb-3 self-start font-medium leading-none text-black" >Parcelamento</label>
              <div data-layername="border" className="h-14 flex gap-10 px-4 py-4 text-sm leading-none  rounded border border-solid border-zinc-600">
                <select
                  id="installment"
                  data-layername="11XDe506"
                  className="bg-transparent border-none appearance-none"
                >
                  <option>11x de 5,06*</option>
                </select>
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a2bf1c3249ea9679dc59bd7a3bae25f881a78658ff5a2fff94ca6b7b5c4ff151?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="" className="object-contain shrink-0 w-4 aspect-square ml-auto" />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default PaymentSection;
