import React from 'react';

const PurchaseSection: React.FC = () => {
  return (
    <section data-layername="border" className="flex flex-col ">
      <div className="grid gap-5">
        <div className="flex flex-col">
          <h3 data-layername="heading3→SuaCompra" className="my-auto basis-auto text-lg font-bold leading-none">
            Sua Compra
          </h3>
          <div className="flex gap-4 mt-5">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/21d6211ed16cc1b40050dc9cd330fc97d2167361964a32ab752606afa26524b8?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="Digital Nexus VIP" className="object-contain shrink-0 w-12 rounded aspect-square" />
            <div className="flex flex-col my-auto">
              <div data-layername="digitalNexusVip" className="text-base font-medium leading-loose text-zinc-400">
                Digital Nexus | Vip
              </div>
              <div data-layername="r4700" className="self-start mt-2 text-base font-bold ">
                R$ 47,00
              </div>
            </div>
          </div>
        </div>
        <hr className="-mx-8 bg-gray-200 h-px"/>
        <div className="flex gap-5 self-start mt-2.5 text-base">
          TOTAL
          <div data-layername="1Item" className="text-right text-zinc-400">1 item</div>
          <div data-layername="r4700" className="font-semibold ">R$ 47,00</div>
        </div>
      </div>
    </section>
  );
};

export default PurchaseSection;
