import React from 'react';
import {ReactComponent as SafePaymentIcon} from "../icons/safe-payment.svg";

const PageProduct: React.FC = () => {
  return (
    <section data-layername="border" className="flex flex-col ">
      <div className="flex gap-5 items-center justify-between">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/21d6211ed16cc1b40050dc9cd330fc97d2167361964a32ab752606afa26524b8?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="Digital Nexus VIP" className="object-contain shrink-0 w-12 rounded aspect-square" />
        <SafePaymentIcon className="h-6 text-gray-500"/>
      </div>
    </section>
  );
};

export default PageProduct;
