import React, { useEffect, useState } from 'react';
import Identification from './components/3step/Identification';
import Payment from './components/3step/Payment';
import Address from './components/3step/Address';
import {ReactComponent as SafePaymentIcon} from "./icons/safe-payment.svg";

interface Config {
  colorTheme: string;
  title: string;
  description: string;
  pixelId?: string; // pixelId pode ser opcional
  [key: string]: any; // Permite outras chaves dinâmicas para garantir flexibilidade
}

interface PaymentMethod {
  id: string;
  name: string;
  imageUrl: string;
}


function App() {
  const [config, setConfig] = useState<Config | null>(null);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  return (
    <div className="bg-gray-200 min-h-screen">
      <header className="py-6 bg-white">
        <div className="mx-auto max-w-6xl flex items-center justify-between">
          <h1>
            Logo
          </h1>
        <SafePaymentIcon className="h-6 text-gray-500"/>
        </div>
      </header>

      <main className="py-6"> 
        <div className="mx-auto max-w-6xl grid grid-cols-3 gap-6">
          <Identification/>
          <Address/>
          <Payment/>
        </div>
      </main>

      <footer className="mt-10">
        <div className="mx-auto max-w-6xl flex flex-col gap-4 items-center">
          <hr className="border-gray-300 mb-6 w-full"/>
          <span>footer</span> 
          <SafePaymentIcon className="h-6 text-gray-500"/>
        </div>
      </footer>
    </div>
  );
}

export default App;
