import React from 'react';
import OfferItem from './OfferItem';

interface Offer {
  id: number;
  image: string;
  title: string;
  price: string;
  description: string;
  specialLabel: string;
  additionalInfo: string;
}

const offers: Offer[] = [
  {
    id: 1,
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/6f3cacaa11b842fc4928dd3439d5155cbe8a1f60aee37e26b583c8df26c40856?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c",
    title: "+12.000 POST PRONTOS (CANVA) 100% EDITAVEIS + BONUS",
    price: "R$ 14,90",
    description: "Não perca a oportunidade para adquirir outros de nossos produtos com um MEGA DESCONTO!",
    specialLabel: "Preço Especial",
    additionalInfo: ""
  },
  {
    id: 2,
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/911857dab3a89a72248932c34e4d27366525e0ba62c580dd35727670322768fe?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c",
    title: "DRIVE COM + DE 50 SOFTWARES COM DIREITO A REVENDA",
    price: "R$ 14,90",
    description: "TOP 1 EM VENDAS!",
    specialLabel: "Preço Especial",
    additionalInfo: ""
  },
  {
    id: 3,
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/97cc9f01eb72924f9cdd8949d2c34b5843c416c4b2fc65c0c07240fa8ab7c70a?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c",
    title: "40.000+ Prompts para ChatGPT (Revenda ou uso) | 1.400+ Ferramentas de IA | 600+ Ferramentas No-Code",
    price: "R$ 14,90",
    description: "MEGA DESCONTO!",
    specialLabel: "Preço Especial",
    additionalInfo: ""
  },
  {
    id: 4,
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/9ef4a3caa3714cdba0a20e15f7a716c12cb0b037bc794b5d9c65d848c4a4c6ab?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c",
    title: "PACK DE PLANILHAS PREMIUM + DE 12.000 PLANILHAS",
    price: "R$ 14,90",
    description: "MEGA DESCONTO!",
    specialLabel: "Preço Especial",
    additionalInfo: ""
  }
];

const OffersSection: React.FC = () => {
  return (
    <section data-layername="border" className="flex flex-col ">
      <div data-layername="background" className="flex flex-col w-full font-medium rounded max-md:max-w-full">
        <div className="flex flex-wrap gap-5 justify-between font-bold max-md:mr-1 max-md:max-w-full">
          <h4 data-layername="ofertasLimitadas" className="text-lg leading-none ">
            Ofertas limitadas
          </h4>
          <button data-layername="adicionarTodas" className="text-sm leading-loose text-amber-600">
            (Adicionar todas)
          </button>
        </div>
        {offers.map((offer) => (
          <OfferItem key={offer.id} {...offer} />
        ))}
      </div>
      <button data-layername="button" className="px-16 py-4 mt-6 text-base font-bold leading-none text-center text-white bg-action rounded max-md:px-5 max-md:max-w-full">
        Comprar agora
      </button>
    </section>
  );
};

export default OffersSection;
